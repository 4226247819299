import { useCasesCount } from '@capturi/api-cases'
import { TextFilterValues } from '@capturi/api-filters'
import { useFilterPeriodContext } from '@capturi/filters'
import { Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC } from 'react'

export const CasesMatchCount: FC<{
  filterRequestModel: Partial<TextFilterValues>
}> = ({ filterRequestModel }) => {
  const { period } = useFilterPeriodContext()
  const { data: casesCount } = useCasesCount(filterRequestModel, period)
  if (casesCount === undefined) return null
  return (
    <Text fontSize="sm">
      <Trans>Conversations: {casesCount}</Trans>
    </Text>
  )
}
